/* eslint-disable */
// ** React Imports
import { lazy, Suspense } from 'react';
import ReactDOM from 'react-dom';

import { BrowserRouter } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import Spinner from './@core/components/spinner/Fallback-spinner';

// ** Ripple Button
// import './@core/components/ripple-button';

// ** PrismJS
import 'prismjs';
import 'prismjs/components/prism-jsx.min';
import 'prismjs/themes/prism-tomorrow.css';

// ** React Perfect Scrollbar
import 'react-perfect-scrollbar/dist/css/styles.css';

// ** React Toastify
import './@core/scss/react/libs/toastify/toastify.scss';

// ** Core styles
import 'keen-slider/keen-slider.min.css';
import 'react-loading-skeleton/dist/skeleton.css';
import './@core/assets/fonts/feather/iconfont.css';
import './@core/scss/core.scss';
import './@core/scss/react/apps/app-calendar.scss';
import './assets/scss/style.scss';
import 'react-intl-tel-input/dist/main.css';
// import 'sweetalert2/src/sweetalert2.scss';

// ** Service Worker
import * as serviceWorker from './serviceWorker';

// ** Lazy load app
const LazyApp = lazy(() => import('./App'));

ReactDOM.render(
  <Suspense fallback={<Spinner />}>
    <BrowserRouter>
      <LazyApp />
    </BrowserRouter>
    <ToastContainer newestOnTop />
  </Suspense>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
